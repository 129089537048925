<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        <v-col class="py-0 pr-0" cols="12 pl-0"
          >Proceso de importación de Facturas</v-col
        >
      </v-card-title>
      <v-card-text>
        <v-row>
          <!--Empresa-->
          <v-col cols="10" md="10" class="pb-0 ml-8">
            <v-autocomplete
              v-model="entFacSelected"
              :items="entsFacs"
              tabindex="8"
              label="Empresa"
              return-object
              item-value="id"
              item-text="value"
              :rules="rules.required"
              outlined
              clearable
              dense
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- Importación para facturas-->
        <v-row>
          <!-- Importación para facturas -->
          <v-col cols="7">
            <v-file-input
              ref="fileFacturas"
              v-model="fileFacturas"
              :disabled="loading"
              :loading="loading"
              id="fileUploadFacturas"
              type="file"
              dense
              label="Archivo de facturas"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              :rules="rules.required"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="1" class="text-left mt-4"> </v-col>
          <v-col cols="3" class="text-right">
            <v-btn
              color="primary"
              class="no-upper-case"
              outlined
              @click="seeFormatoArchivoFactura"
            >
              Ver formato
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <!-- Importación inputación contable -->
          <v-col cols="7">
            <v-file-input
              ref="fileImputContable"
              v-model="fileImputContable"
              :disabled="loading || !fileFacturas || fileFacturas.length === 0 "
              :loading="loading"
              id="fileUploadInputContable"
              type="file"
              dense
              label="Archivo de imputaciones contables"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="1" class="text-left mt-2">
            <v-tooltip top max-width="20%">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="primary">
                  {{ infoIcon }}
                </v-icon>
              </template>
              <span
                >Para cargar un archivo de "imputaciones contables" debe
                seleccionar un archivo de facturas.</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn
              color="primary"
              class="no-upper-case"
              outlined
              @click="seeFormatoImputContables"
            >
              Ver formato
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <!-- Importación centro costos -->
          <v-col cols="7">
            <v-file-input
              ref="fileCentroCosto"
              v-model="fileCentroCosto"
              :disabled="loading || !fileFacturas || fileFacturas.length === 0"
              :loading="loading"
              id="fileUploadCentroCosto"
              type="file"
              dense
              label="Archivo de centros de costos"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="1" class="text-left mt-2">
            <v-tooltip top max-width="20%">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="primary">
                  {{ infoIcon }}
                </v-icon>
              </template>
              <span
                >Para cargar un archivo de "centros de costos" debe seleccionar
                archivo de facturas e imputaciones contables.</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn
              color="primary"
              class="no-upper-case"
              outlined
              @click="seeFormatoCentroCosto"
            >
              Ver formato
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-spacer></v-spacer>
          <v-btn class="mr-4" outlined @click="closeModal()">
            Cerrar
          </v-btn>
          <v-btn
            type="submit"
            class="mr-6"
            :disabled="!entFacSelected || !fileFacturas || (fileFacturas && fileCentroCosto && !fileImputContable)"
            @click="validarImportacion()"
            :loading="isLoadingBtn"
            form="form"
            color="primary"
          >
            Validar
          </v-btn>
        </v-row>

        <!-- </v-form> -->
      </v-card-text>
      <v-dialog
        v-if="openModalCSError"
        v-model="openModalCSError"
        max-width="75%"
        @keydown.esc="closeAndReloadCSError"
        persistent
      >
        <ImportacCSError
          :fileCentroCosto="fileCentroCosto"
          :fileImputContable="fileImputContable"
          :entFacSelected="entFacSelected"
          :itemsValidados="itemsValidados"
          @closeAndReloadCSError="closeAndReloadCSError"
          @closeModalImportar="closeModalImportar"
        ></ImportacCSError>
      </v-dialog>
    </v-container>
    <FormatoArchivoDialog
      :formatoTitle="formatoTitle"
      :headers="headers"
      :dataToExport="dataToExport"
      :datosFormatoArchivo="datosFormatoArchivo"
      :openFormatoArchivoDialog.sync="showSeeFormatModal"
    />
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import ImportacCSError from "./ImportacCSError.vue";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";

export default {
  name: "NuevoaImportacionFact",
  directives: { mask },
  components: { FormatoArchivoDialog, ImportacCSError },
  data() {
    return {
      isLoadingBtn: false,
      infoIcon: enums.icons.SNB_INFO,
      fileNameArchivo: null,
      openModalCSError: false,
      fileNameArchivoCentroCosto: null,
      fileNameArchivoInputCont: null,
      entsFacs: [],
      entFacSelected: null,
      fileFacturas: null,
      fileCentroCosto: null,
      fileImputContable: null,
      rules: rules,
      loading: false,
      isFormValid: false,
      file: null,
      formatoTitle: "",
      headers: [],
      datosFormatoArchivo: [],
      formatoCentrosCostos: [],
      formatoInputContables: [],
      showSeeFormatModal: false,
      dataToExport: {},
      formData: null,
      itemsValidados: []
    };
  },
  created() {
    this.setEntidades();
  },
  methods: {
    ...mapActions({
      fetchTiposProcesosFormatosById:
        "prestadores/fetchTiposProcesosFormatosById",
      validarArchivos: "prestadores/validarArchivos",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      setAlert: "user/setAlert"
    }),
    async setEntidades() {
      const entFacs = await this.getEntidadesFacturantes();
      this.entsFacs = entFacs;
      if (this.entsFacs.length === 1) {
        this.entFacSelected = this.entsFacs[0];
      } else {
        this.entFacSelected = null;
      }
    },
    async seeFormatoArchivoFactura() {
      this.formatoTitle = "Formato de archivo de factura";
      const formatoProceso = await this.fetchTiposProcesosFormatosById(39);
      this.datosFormatoArchivo = formatoProceso;
      this.headers = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        },
        {
          text: "Dato ejemplo",
          value: "datoEjemplo",
          align: "start",
          sortable: false
        },
        {
          text: "Orden de campo",
          value: "ordenCampo",
          align: "start",
          sortable: false
        }
      ];
      this.showSeeFormatModal = true;
      this.dataToExport = {
        formatoName: "Formato de importación de facturas",
        data: [
          {
            ["Fecha"]: "23/02/2024",
            ["FechaVenc"]: "23/02/2024",
            ["FechaContab"]: "23/02/2024",
            ["CodigoProv"]: "686",
            ["TipoComp"]: "Factura",
            ["Letra"]: "A",
            ["Sucursal"]: "1",
            ["Número"]: "99",
            ["Concepto"]: "Ambulatorio",
            ["Neto"]: "30000",
            ["Alicuota"]: "Tasa diferencial",
            ["IVA"]: "3150",
            ["per_iva"]: "500",
            ["per_ib"]: "350",
            ["imp_int"]: "0",
            ["OtrosConc"]: "0",
            ["TipoOperacion"]: "Prestación de servicios",
            ["Periodo"]: "202402",
            ["Observaciones"]: "Refacturación 202401",
            ["Concepto2"]: "",
            ["Neto2"]: "",
            ["Iva2"]: "",
            ["Alicuota2"]: "",
            ["Concepto3"]: "",
            ["Neto3"]: "",
            ["Iva3"]: "",
            ["Alicuota3"]: "",
            ["Total"]: "34000",
            ["Nro CAE"]: "513454875135775",
            ["FechaVencCAE"]: "28/02/2024",
            ["TipoEmisión"]: "E"
          }
        ]
      };
    },
    seeFormatoCentroCosto() {
      this.formatoTitle = "Formato de centros de costo";
      this.formatoCentrosCostos = [
        {
          nombreCampo: "CodigoProv",
          longMax: 10,
          formato: "Texto",
          requerido: true,
          descripcion: "Código del proveedor",
          datoEjemplo: "a100",
          ordenCampo: 1
        },
        {
          nombreCampo: "TipoComp",
          longMax: 50,
          formato: "Texto",
          requerido: true,
          descripcion: "Nombre del tipo de comprobante",
          datoEjemplo: "Factura",
          ordenCampo: 2
        },
        {
          nombreCampo: "Letra",
          longMax: 2,
          formato: "Texto",
          requerido: true,
          descripcion: "Letra",
          datoEjemplo: "R",
          ordenCampo: 3
        },
        {
          nombreCampo: "Sucursal",
          longMax: 2,
          formato: "Texto",
          requerido: true,
          descripcion: "Sucursal",
          datoEjemplo: "1",
          ordenCampo: 4
        },
        {
          nombreCampo: "Número",
          longMax: 4,
          formato: "Número",
          requerido: true,
          descripcion: "Número",
          datoEjemplo: "99",
          ordenCampo: 5
        },
        {
          nombreCampo: "CentroCostos",
          longMax: 9,
          formato: "Texto",
          requerido: true,
          descripcion: "Centro de costo",
          datoEjemplo: "14-sucursal rosario",
          ordenCampo: 6
        },
        {
          nombreCampo: "Importe",
          longMax: 9,
          formato: "Número",
          requerido: true,
          descripcion: "Importe",
          datoEjemplo: "10500",
          ordenCampo: 7
        }
      ];
      this.datosFormatoArchivo = this.formatoCentrosCostos;
      this.headers = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "Formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        },
        {
          text: "Dato ejemplo",
          value: "datoEjemplo",
          align: "start",
          sortable: false
        },
        {
          text: "Orden de campo",
          value: "ordenCampo",
          align: "start",
          sortable: false
        }
      ];
      this.showSeeFormatModal = true;
      this.dataToExport = {
        formatoName: "Formato de centro de costos",
        data: [
          {
            ["CodigoProv"]: "686",
            ["TipoComp"]: "Factura",
            ["Letra"]: "A",
            ["Sucursal"]: "1",
            ["Numero"]: "99",
            ["CentroCosto"]: "Sucursal1",
            ["Importe"]: "30000"
          }
        ]
      };
    },
    seeFormatoImputContables() {
      this.formatoTitle = "Formato de Inputación contable";
      this.formatoInputContables = [
        {
          nombreCampo: "CodigoProv",
          longMax: 10,
          formato: "Texto",
          requerido: true,
          descripcion: "Código del proveedor",
          datoEjemplo: "a100",
          ordenCampo: 1
        },
        {
          nombreCampo: "TipoComp",
          longMax: 50,
          formato: "Texto",
          requerido: true,
          descripcion: "Nombre del tipo de comprobante",
          datoEjemplo: "Factura",
          ordenCampo: 2
        },
        {
          nombreCampo: "Letra",
          longMax: 2,
          formato: "Texto",
          requerido: true,
          descripcion: "Letra",
          datoEjemplo: "R",
          ordenCampo: 3
        },
        {
          nombreCampo: "Sucursal",
          longMax: 2,
          formato: "Texto",
          requerido: true,
          descripcion: "Sucursal",
          datoEjemplo: "1",
          ordenCampo: 4
        },
        {
          nombreCampo: "Número",
          longMax: 4,
          formato: "Número",
          requerido: true,
          descripcion: "Número",
          datoEjemplo: "99",
          ordenCampo: 5
        },
        {
          nombreCampo: "Cuenta",
          longMax: 9,
          formato: "Número",
          requerido: true,
          descripcion: "Número de cuenta",
          datoEjemplo: "1.01.02.01",
          ordenCampo: 6
        },
        {
          nombreCampo: "Importe",
          longMax: 9,
          formato: "Número",
          requerido: true,
          descripcion: "Importe",
          datoEjemplo: "10500",
          ordenCampo: 7
        }
      ];
      this.datosFormatoArchivo = this.formatoInputContables;
      this.headers = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "Formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        },
        {
          text: "Dato ejemplo",
          value: "datoEjemplo",
          align: "start",
          sortable: false
        },
        {
          text: "Orden de campo",
          value: "ordenCampo",
          align: "start",
          sortable: false
        }
      ];
      this.showSeeFormatModal = true;
      this.dataToExport = {
        formatoName: "Formato de imputación contable",
        data: [
          {
            ["CodigoProv"]: "686",
            ["TipoComp"]: "Factura",
            ["Letra"]: "A",
            ["Sucursal"]: "1",
            ["Numero"]: "99",
            ["Cuenta"]: "5.01.02.01",
            ["Importe"]: "30000"
          }
        ]
      };
    },
    async validarImportacion() {
      this.isLoadingBtn = true;
      this.formData = new FormData();
      this.formData.append("archivoFacturas", this.fileFacturas);
      this.formData.append("archivoCentrosCostos", this.fileCentroCosto);
      this.formData.append("archivoImputContables", this.fileImputContable);
      this.formData.append("entFacId", this.entFacSelected.id);
      try {
        const res = await this.validarArchivos(this.formData);
        this.itemsValidados = res;
        this.isLoadingBtn = false;
        this.openValidation();
      } catch {
        this.isLoadingBtn = false;
      }
    },
    openValidation() {
      this.openModalCSError = true;
    },
    closeAndReloadCSError() {
      this.openModalCSError = false;
      this.closeModal();
      this.$emit("applyFilters");
    },
    closeModalImportar() {
      this.openModalCSError = false;
    },
    closeModal() {
      this.$emit("closeAndReloadNew");
    }
  }
};
</script>
